import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
export default /*@__PURE__*/ _defineComponent({
    __name: 'DeepLinksTab',
    setup (__props) {
        const domains = [];
        "1";
        "1";
        domains.push('bGVvbmJldHMuY29t');
        domains.push('bGVvbi5iZXQ=');
        const webIntents = [
            'aHR0cHM6Ly8wd2w1Mi5jc2IuYXBwLw=='
        ];
        for (const domain of domains){
            webIntents.push(btoa(`https://${atob(domain)}`));
            webIntents.push(btoa(`https://www.${atob(domain)}`));
        }
        const decode = (value)=>atob(value);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("ul", {
                class: _normalizeClass(_ctx.$style['deep-links'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(webIntents, (webIntent, index)=>_createElementVNode("li", {
                        key: index
                    }, [
                        _createElementVNode("a", {
                            href: decode(webIntent)
                        }, _toDisplayString(decode(webIntent)), 9, _hoisted_1)
                    ])), 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'DeepLinksTab'
                ]
            ]);
        };
    }
});
