import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__code"
};
const _hoisted_2 = {
    class: "devtools__code"
};
const _hoisted_3 = {
    class: "devtools__code"
};
const _hoisted_4 = {
    class: "devtools__code"
};
const _hoisted_5 = {
    class: "devtools__code"
};
import { storeToRefs } from 'pinia';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CaptchaV3Tab',
    setup (__props) {
        const { captcha: captchaConfig } = storeToRefs(useSiteConfigStore());
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", null, [
                    _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Login enabled:", -1)),
                    _createElementVNode("code", _hoisted_1, _toDisplayString(_unref(captchaConfig)?.recaptchaV3.loginEnabled), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[1] || (_cache[1] = _createElementVNode("h4", null, "Password recovery enabled:", -1)),
                    _createElementVNode("code", _hoisted_2, _toDisplayString(_unref(captchaConfig)?.recaptchaV3.loginEnabled), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Registration enabled:", -1)),
                    _createElementVNode("code", _hoisted_3, _toDisplayString(_unref(captchaConfig)?.recaptchaV3.registrationEnabled), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Captcha site key", -1)),
                    _createElementVNode("code", _hoisted_4, _toDisplayString(_unref(captchaConfig)?.recaptchaV3.siteKey), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("h4", null, "Captcha timeout", -1)),
                    _createElementVNode("code", _hoisted_5, _toDisplayString(_unref(captchaConfig)?.recaptchaV3.timeout), 1)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CaptchaV3Tab'
                ]
            ]);
        };
    }
});
