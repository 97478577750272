// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resources-container_SfCgh{padding:20px;border-radius:8px;box-shadow:0 2px 8px #0000001a}.resources-table_CQ73o{width:100%;margin-bottom:20px;border-collapse:collapse}.table__td_PqWA4,.table__th_AVtQh{flex:1;padding:12px;text-align:left;white-space:nowrap;border:1px solid #ddd}.title_KGLkF{font-size:24px}.exclude-container_gG8zk,.title_KGLkF{margin-bottom:20px}.exclude-container_gG8zk label{margin-right:10px;font-weight:700}.exclude-container_gG8zk select{min-width:150px;padding:5px;font-size:14px;border:1px solid #ddd;border-radius:4px}.total-scores_VrElL h3{margin-bottom:10px;font-size:20px;color:#333}.total-scores_VrElL p{margin:5px 0;font-size:16px;color:#666}.field--name_TtP35{max-width:80vmax;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resources-container": `resources-container_SfCgh`,
	"resources-table": `resources-table_CQ73o`,
	"table__td": `table__td_PqWA4`,
	"table__th": `table__th_AVtQh`,
	"title": `title_KGLkF`,
	"exclude-container": `exclude-container_gG8zk`,
	"total-scores": `total-scores_VrElL`,
	"field--name": `field--name_TtP35`
};
export default ___CSS_LOADER_EXPORT___;
